import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ROLES } from "services/endPoints";
import { privateRequest } from "services/privateRequest";

const initialState = {
  roleList: [],
};

export const getRole = createAsyncThunk("entity/getRole", async () => {
  const res = await privateRequest.get(ROLES);
  return {
    data: res?.data?.data || [],
  };
});

export const addRole = createAsyncThunk(
  "entity/addRole",
  async (payload, { dispatch }) => {
    const res = await privateRequest.post(ROLES, payload);
    if (res) {
      toast?.success?.(res?.data?.message);
      await dispatch(getRole());
    }
    return {
      data: res?.data?.data || [],
    };
  }
);

export const editRole = createAsyncThunk(
  "entity/editRole",
  async ({ payload, id }, { dispatch }) => {
    const res = await privateRequest.put(ROLES + "/" + id, payload);
    if (res) {
      toast?.success?.(res?.data?.message);
      await dispatch(getRole());
    }
    return {
      data: res?.data?.data || [],
    };
  }
);

export const deleteRole = createAsyncThunk(
  "entity/deleteRole",
  async ( id , { dispatch }) => {
    const res = await privateRequest.delete(ROLES + "/" + id);
    if (res) {
      toast?.success?.(res?.data?.message);
      await dispatch(getRole());
    }
    return {
      data: res?.data?.data || [],
    };
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.roleList = action.payload.data;
    });
  },
});

export default roleSlice.reducer;
export const {} = roleSlice.actions;

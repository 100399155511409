import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_ISSUES,
  DELETE_ISSUE,
  EDIT_ISSUE,
  ISSUE_DATA,
  PROJECT_LIST,
} from "services/endPoints";
import { projectSprintBacklogDetails } from "./backlogSlice";
import { getProjectId, getToken } from "utils/utils";
import toast from "react-hot-toast";
import axios from "axios";
import { privateRequest } from "services/privateRequest";

const initialState = {
  loading: false, //Button Loader
  projectDetails: [],
  selectedIssue: {},
  issueDetails: {},
  projectsList: [],
};

export const getProjectsList = createAsyncThunk(
  "entity/getProjectsList",
  async (params) => {
    const res = await privateRequest.get(PROJECT_LIST, { params });
    return {
      data: res?.data?.data?.data || [],
    };
  }
);

export const getProjectDetails = createAsyncThunk(
  "entity/getProjectDetails",
  async (id) => {
    const res = await privateRequest.get(`/api/issues/${id}/pre-details`);
    return {
      data: res?.data?.data || [],
    };
  }
);

export const createIssue = createAsyncThunk(
  "entity/createIssue",
  async (params, { dispatch }) => {
    const res = await privateRequest.post(CREATE_ISSUES, params);
    toast.success(res.data.message);
  }
);

export const getIssueData = createAsyncThunk(
  "entity/getIssueData",
  async ({ id, type = null }) => {
    const res = await privateRequest.get(ISSUE_DATA + `/${id}`);
    // const res = await axios.get(
    //   "http://85c1-2401-4900-8814-6fa5-1d98-5bc-f85f-a359.ngrok-free.app/api/issues/13",
    //   {
    //     headers: {
    //       Authorization: `Bearer ${getToken()}`,
    //     },
    //   }
    // );
    return {
      data: res?.data?.data || [],
      details: type,
    };
  }
);

export const editIssue = createAsyncThunk(
  "entity/editIssue",
  async ({ id, formData }, { dispatch }) => {
    const res = await privateRequest.put(EDIT_ISSUE + `/${id}`, formData);
    toast.success(res?.data?.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
  }
);

export const editSingleFieldIssue = createAsyncThunk(
  "entity/editIssue",
  async ({ id, formData, filterParams }, { dispatch }) => {
    const res = await privateRequest.patch(EDIT_ISSUE + `/${id}`, formData);
    if(res) toast.success(res?.data?.message);
    await dispatch(
      projectSprintBacklogDetails({
        data: { id: getProjectId() },
        params: filterParams,
      })
    );
    // dispatch(getIssueData({ id }));
  }
);

export const deleteIssue = createAsyncThunk(
  "entity/deleteIssue",
  async (id, { dispatch }) => {
    const res = await privateRequest.delete(DELETE_ISSUE + `/${id}`);
    toast.success(res?.data?.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
  }
);

const issueSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedIssue: (state, action) => {
      state.selectedIssue = action.payload;
    },
    setIssueDetailsEmpty: (state, action) => {
      state.issueDetails = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsList.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getProjectsList.fulfilled, (state, action) => {
        state.projectsList = action.payload.data;
        state.loader = false;
      })
      .addCase(getProjectsList.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getProjectDetails.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        state.projectDetails = action.payload.data;
        state.loader = false;
      })
      .addCase(getProjectDetails.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getIssueData.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getIssueData.fulfilled, (state, action) => {
        state.loader = false;
        if (action?.payload?.details) {
          state.issueDetails = action?.payload?.data;
        } else {
          state.selectedIssue = action.payload.data;
        }
      })
      .addCase(getIssueData.rejected, (state, action) => {
        state.loader = false;
      });
  },
});

export default issueSlice.reducer;
export const { setLoading, setSelectedIssue, setIssueDetailsEmpty } =
  issueSlice.actions;

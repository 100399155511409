import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {
  CREATE_SPRINT,
  DELETE_SPRINT_DATA,
  EDIT_SPRINT_DATA,
  GET_PROJECT_EPICS,
  SPRINT_DATA,
  SPRINT_LIST,
} from "services/endPoints";
import { privateRequest } from "services/privateRequest";
import { getProjectId, getToken } from "utils/utils";

const initialState = {
  sprintsBacklogList: [],
  loading: false, //Button Loader,
  params: {},
  selectedSprint: {},
  sprintsList: [],
  filterParams: [],
  createNewIssue: {
    data: null,
    open: false,
  },
  projectEpics: [],
  filterDropDown: {
    open: false,
    data: null,
  },
};

export const getSprintsList = createAsyncThunk(
  "entity/getSprintsList",
  async (params) => {
    const res = await privateRequest.get(SPRINT_LIST + `/${getProjectId()}`);
    return {
      data: res?.data?.data || [],
    };
  }
);

export const createSprint = createAsyncThunk(
  "entity/createSprint",
  async (parameter, { dispatch }) => {
    const res = await privateRequest.post(CREATE_SPRINT, null, {
      params: parameter,
    });
    toast.success(res?.data?.message);
    dispatch(getSprintsList());
  }
);

export const getSprintData = createAsyncThunk(
  "entity/getSprintData",
  async (id) => {
    const res = await privateRequest.get(SPRINT_DATA + `/${id}`);
    return {
      data: res?.data?.data || [],
    };
  }
);

export const getProjectEpics = createAsyncThunk(
  "entity/getProjectEpics",
  async (id) => {
    const res = await privateRequest.get(GET_PROJECT_EPICS + `/${id}`);
    return {
      data: res?.data?.data || [],
    };
  }
);

export const startSprint = createAsyncThunk(
  "entity/startSprint",
  async ({ id, formData }, { dispatch }) => {
    const res = await privateRequest.post(`/api/sprints/${id}/start`, formData);
    toast.success(res?.data?.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
    dispatch(getSprintsList());
    // await axios.post(
    //   `http://103.165.118.9:5100/api/sprints/${id}/start`,
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${getToken()}`,
    //     },
    //   }
    // );
  }
);

export const editSprint = createAsyncThunk(
  "entity/editSprint",
  async ({ id, formData }, { dispatch }) => {
    const res = await privateRequest.put(`/api/sprints/${id}`, formData);
    toast.success(res?.data?.message);
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
    // await axios.put(
    //   `http://103.165.118.9:5100/api/sprints/${id}`,
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${getToken()}`,
    //     },
    //   }
    // );
  }
);

export const deleteSprint = createAsyncThunk(
  "entity/deleteSprint",
  async ({ id }, { dispatch }) => {
    const res = await privateRequest.delete(DELETE_SPRINT_DATA + `/${id}`);
    dispatch(getSprintsList());
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
  }
);

export const completeSprint = createAsyncThunk(
  "entity/completeSprint",
  async ({sprintId, parameter}, { dispatch }) => {
    const res = await privateRequest.patch(`api/sprints/${sprintId}/complete`, null, {
      params: parameter,
    });
    toast.success(res?.data?.message);
    dispatch(getSprintsList());
    dispatch(projectSprintBacklogDetails({ data: { id: getProjectId() } }));
  }
);

export const projectSprintBacklogDetails = createAsyncThunk(
  "entity/projectSprintBacklogDetails",
  async ({ data, params }) => {
    const res = await privateRequest.get(`/api/projects/${data.id}/backlog`, {
      params,
    });
    return {
      data: res?.data?.data || [],
    };
  }
);

const backlogSlice = createSlice({
  name: "sprints",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedSprint: (state, action) => {
      state.selectedSprint = action.payload;
    },
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
    setCreateNewIssue: (state, action) => {
      state.createNewIssue = action.payload;
    },
    setFilterDropDownBacklog: (state, action) => {
      state.filterDropDown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSprintsList.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getSprintsList.fulfilled, (state, action) => {
        state.sprintsList = action.payload.data;
        state.loader = false;
      })
      .addCase(getSprintsList.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(projectSprintBacklogDetails.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(projectSprintBacklogDetails.fulfilled, (state, action) => {
        state.sprintsBacklogList = action.payload.data;
        state.loader = false;
      })
      .addCase(projectSprintBacklogDetails.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getSprintData.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getSprintData.fulfilled, (state, action) => {
        state.selectedSprint = action.payload.data;
        state.loader = false;
      })
      .addCase(getSprintData.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getProjectEpics.fulfilled, (state, action) => {
        state.projectEpics = action.payload.data;
      })
  },
});

export default backlogSlice.reducer;
export const { setSelectedSprint, setFilterParams, setCreateNewIssue, setFilterDropDownBacklog } = backlogSlice.actions;

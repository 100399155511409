import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ASSIGN_PROJECT_MEMBERS, PROJECT_ROLES, USERS } from "services/endPoints";
import { privateRequest } from "services/privateRequest";
import { getProjectId } from "utils/utils";

const initialState = {
  projectsMemberList: [],
  projectsRoleList: [],
  allProjectsMemberList: [],
  memberListDropDown: false,
};

export const getProjectsMemberList = createAsyncThunk(
  "entity/getProjectsMemberList",
  async ({ projectId }) => {
    const res = await privateRequest.get(
      `/api/user-projects/projects/${projectId}/users`
    );
    return {
      data: res?.data?.data || [],
    };
  }
);

export const getAllProjectsMemberList = createAsyncThunk(
    "entity/getAllProjectsMemberList",
    async () => {
      const res = await privateRequest.get(USERS);
      return {
        data: res?.data?.data || [],
      };
    }
  );

export const getProjectsRoleList = createAsyncThunk(
  "entity/getProjectsRoleList",
  async (params) => {
    const res = await privateRequest.get(PROJECT_ROLES, { params });
    return {
      data: res?.data?.data || [],
    };
  }
);

export const assignProjectMember = createAsyncThunk(
    "entity/assignProjectMember",
    async (payload, {dispatch}) => {
      const res = await privateRequest.post(ASSIGN_PROJECT_MEMBERS, payload);
      toast.success(res.data.message)
      dispatch(getAllProjectsMemberList())
      dispatch(getProjectsMemberList({ projectId: getProjectId() }));
      return {
        data: res?.data?.data || [],
      };
    }
  );

const assignMemberSlice = createSlice({
  name: "assignMember",
  initialState,
  reducers: {
    setMemberListDropDown : (state, action)=>{
      state.memberListDropDown = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsMemberList.fulfilled, (state, action) => {
        state.projectsMemberList = action.payload.data;
      })
      .addCase(getProjectsRoleList.fulfilled, (state, action) => {
        state.projectsRoleList = action.payload.data;
      })
      .addCase(getAllProjectsMemberList.fulfilled, (state, action) => {
        state.allProjectsMemberList = action.payload.data;
      });
  },
});

export default assignMemberSlice.reducer;
export const { setMemberListDropDown } = assignMemberSlice.actions;
